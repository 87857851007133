<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.classificacao_instrumento.titulos.listagem')"
      :titulo-botao-novo="$t('modulos.classificacao_instrumento.titulos.novo')"
      :total-registros="items.length"
      :sem-botao-ok="!buscarPermissao('ClassificacaoInstrumento', 'Inserir')"
      :sem-filtro="!buscarPermissao('ClassificacaoInstrumento', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <botao-padrao
      v-if="
        podeExcluir && buscarPermissao('ClassificacaoInstrumento', 'Deletar')
      "
      class="my-2"
      outlined
      color="danger"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <v-divider class="ma-4 ma-md-0 mb-md-4" />
    <v-treeview
      v-model="selecionados"
      :items="items"
      transition
      :item-key="items.id"
      open-on-click
      expand-icon=""
      :selectable="true"
      :mostrar-seletor="buscarPermissao('ClassificacaoInstrumento', 'Deletar')"
      selected-color="secundary"
    >
      <template v-slot:prepend="{ item, open }">
        <div class="d-flex align-center">
          <v-icon v-if="item.children?.length > 0">
            {{ open ? '$downArrow' : '$rightArrow' }}
          </v-icon>
          <dropdown-padrao text color="secondary">
            <template #botao>
              <v-icon> $dotsVertical </v-icon>
            </template>
            <div>
              <dropdown-padrao-item
                v-if="buscarPermissao('ClassificacaoInstrumento', 'Editar')"
                text
                color="secondary"
                @click="abrirEditar(item)"
              >
                {{ $t('geral.botoes.editar') }}
              </dropdown-padrao-item>
              <dropdown-padrao-item
                v-if="buscarPermissao('ClassificacaoInstrumento', 'Inserir')"
                text
                color="secondary"
                @click="abrirAdicionar(item)"
              >
                {{ $t('geral.botoes.criar') }}
              </dropdown-padrao-item>
            </div>
          </dropdown-padrao>
          <div class="descricao-item">
            {{ item.descricao }}
          </div>
        </div>
      </template>
    </v-treeview>
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import ClassificacaoInstrumentoService from '@common/services/cadastros/ClassificacaoInstrumentoService';
import Filtro from './filtro.vue';
import helpers from '@/common/utils/helpers';
export default {
  components: {
    CabecalhoPagina,
    Filtro,
  },
  data() {
    return {
      items: [],
      selecionados: [],
    };
  },
  computed: {
    podeExcluir: function () {
      return this.selecionados.length > 0;
    },
  },
  mounted() {
    this.listarRegistros();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.classificacao_instrumento.titulos.listagem')
    );
  },
  created() {
    helpers.redirecionarSemPermissao(
      this,
      'ClassificacaoInstrumento',
      'Listar'
    );
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado };
      this.listarRegistros();
    },
    listarRegistros: function () {
      let parametros = {
        ...this.filtroAplicado,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      ClassificacaoInstrumentoService.listar(parametros)
        .then((res) => {
          this.items = res.data;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');

        this.selecionados.forEach((item) => {
          ClassificacaoInstrumentoService.excluir(item)
            .then(() => {
              this.listarRegistros();
              this.toastSucesso(
                this.$t(`modulos.classificacao_instrumento.exclusao_sucesso`)
              );
            })
            .finally(() => {
              this.$store.dispatch('Layout/terminarCarregamento');
              this.selecionados = [];
            });
        });
      });
    },
    abrirNovo: function () {
      this.$router.push({ name: 'classificacao-instrumento-novo' });
    },
    abrirEditar: function (item) {
      this.$router.push({
        name: 'classificacao-instrumento-editar',
        params: { id: item.id },
      });
    },
    abrirAdicionar: function (item) {
      this.$router.push({
        name: 'classificacao-instrumento-novo',
        params: { paiId: item.id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.descricao-item {
  white-space: nowrap;
}
</style>
