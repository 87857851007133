<template>
  <div class="row">
    <input-text
      v-model="filtro.descricao"
      class="col-12"
      :label="$t('modulos.classificacao_instrumento.filtro.descricao')"
      em-filtro
    />
  </div>
</template>
<script>
export default {
  props: {
    filtro: {},
  },
  data() {
    return {
    };
  },
};
</script>

